import React from 'react'
import cx from 'classnames'
import style from './OutputSettings.module.scss'

const SEPARATORS = {
  'New Line': '\n',
  Space: ' ',
  Comma: ',',
  'Comma with Space': ', ',
  Tab: '\t',
  Pipe: '|',
  Semicolon: ';',
  Colon: ':',
  Dot: '.',
}

const Toggle = ({ label, onToggle, active }) => (
  <div
    className={cx(style.toggle, {
      [style.active]: active,
    })}
    onClick={onToggle}>
    {label}
  </div>
)

const Button = ({ label, onClick }) => (
  <div className={style.button} onClick={onClick}>
    {label}
  </div>
)

const Dropdown = ({ options, value, onChange }) => (
  <select
    className={style.dropdown}
    value={value}
    onChange={e => onChange(e.target.value)}>
    {Object.entries(options).map(([label, option]) => (
      <option key={option} value={option}>
        {label}
      </option>
    ))}
  </select>
)

const OutputSettings = ({
  transformSettings,
  showCopyButton,
  onCopy,
  onChange,
}) => {
  const onToggle = key => {
    onChange(settings => ({
      ...settings,
      [key]: !settings[key],
    }))
  }

  const onParamChange = (key, value) => {
    onChange(settings => ({
      ...settings,
      [key]: value,
    }))
  }

  console.log(transformSettings)
  return (
    <div>
      <Toggle
        label='Trim'
        onToggle={() => onToggle('trim')}
        active={transformSettings['trim']}
      />
      <Toggle
        label='Quote'
        onToggle={() => onToggle('quote')}
        active={transformSettings['quote']}
      />
      <Toggle
        label='Wrap'
        onToggle={() => onToggle('wrapInBraces')}
        active={transformSettings['wrapInBraces']}
      />
      <Toggle
        label='Filter Empty'
        onToggle={() => onToggle('filterEmpty')}
        active={transformSettings['filterEmpty']}
      />
      <Toggle
        label='Unique'
        onToggle={() => onToggle('unique')}
        active={transformSettings['unique']}
      />
      <Dropdown
        options={SEPARATORS}
        onChange={value => onParamChange('separator', value)}
        value={transformSettings['separator']}
      />
      <input
        value={transformSettings['replacer']}
        placeholder='replacer'
        onChange={e => onParamChange('replacer', e.target.value)}
      />
      {showCopyButton ? <Button onClick={onCopy} label='Copy' /> : null}
    </div>
  )
}

export default OutputSettings
