import logo from './logo.svg'
import './App.css'
import { useState } from 'react'
import Input from './Input'
import style from './App.module.scss'
import OutputSettings from './OutputSettings'

const parseRegexpWithFlags = pattern => {
  const parsed = /^\/(.*)\/([gi]*)$/.exec(pattern)

  const regex = parsed ? parsed[1] : pattern
  const flags = parsed ? parsed[2] : ''

  try {
    return new RegExp(regex, flags)
  } catch (e) {
    return pattern
  }
}

const applyTransforms = (value, transformSettings) => {
  if (!value || !Array.isArray(value)) {
    return null
  }

  console.log(value)

  const transformed = value
    .map(v => {
      if (transformSettings.trim) {
        v = v.trim()
      }
      if (transformSettings.quote) {
        v = `"${v}"`
      }
      if (transformSettings.replacer !== '') {
        let replacer = parseRegexpWithFlags(transformSettings.replacer)
        v = v.replace(replacer, '')
      }
      return v
    })
    .filter(v => (transformSettings.filterEmpty ? v !== '' : true))

  const maybeDeduped = transformSettings.unique
    ? Array.from(new Set(transformed))
    : transformed

  const joined = maybeDeduped.join(transformSettings.separator)

  return transformSettings.wrapInBraces ? `[${joined}]` : joined
}

function App() {
  const [inputValue, setInputValue] = useState(null)

  const [transformSettings, setTransformSettings] = useState({
    trim: true,
    quote: false,
    wrapInBraces: false,
    separator: '\n',
    filterEmpty: false,
    replacer: '',
    unique: false,
  })

  const output = applyTransforms(inputValue, transformSettings)

  const toggleCopy = () => {
    window.navigator.clipboard.writeText(output)
  }

  console.log(inputValue)

  return (
    <div className={style.app}>
      <Input onChange={setInputValue} />
      <OutputSettings
        transformSettings={transformSettings}
        onChange={setTransformSettings}
        showCopyButton={output != null}
        onCopy={toggleCopy}
      />
      {output != null ? (
        <code
          style={{ whiteSpace: 'pre', textAlign: 'left', overflow: 'auto' }}>
          {output}
        </code>
      ) : null}
    </div>
  )
}

export default App
