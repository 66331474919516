import React, { useEffect, useState } from 'react'
import papaparse from 'papaparse'
import style from './Input.module.scss'

const Input = ({ onChange }) => {
  const [rawInput, setRawInput] = useState('')
  const [mode, setMode] = useState('newline')
  const [column, setColumn] = useState('')
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumn(null)
    setColumns([])
  }, [mode])

  useEffect(() => {
    if (mode === 'newline') {
      onChange(rawInput.split('\n').filter(v => v.trim() !== ''))
    }
    if (mode === 'space') {
      onChange(rawInput.split(/\s+/).filter(v => v.trim() !== ''))
    }
    if (mode === 'comma') {
      onChange(rawInput.split(',').filter(v => v.trim() !== ''))
    }
    if (mode === 'csv') {
      const data = papaparse.parse(rawInput.trim(), { header: true })
      if (
        (columns.length === 0 && data.meta.fields.length > 0) ||
        (data.meta.fields.length === 0 && columns.length > 0)
      ) {
        console.log('Setting Columns', data.meta.fields)
        setColumns(data.meta.fields)
      }

      if (column != null) {
        const val = data.data.map(row => row[column])
        if (val.every(value => value != null)) {
          onChange(val.filter(v => v.trim() !== ''))
        }
      }
    }
  }, [rawInput, mode, columns, setColumns, column])

  return (
    <div className={style.input}>
      <div className={style.modeSelect}>
        <select value={mode} onChange={e => setMode(e.target.value)}>
          <option value='newline'>New Line Separated</option>
          <option value='space'>Space Separated</option>
          <option value='comma'>Comma Separated</option>
          <option value='csv'>CSV</option>
        </select>
        {columns.length > 0 ? (
          <select value={column} onChange={e => setColumn(e.target.value)}>
            <option value={''}>Select Column</option>
            {columns.map(column => (
              <option value={column} key={column}>
                {column}
              </option>
            ))}{' '}
          </select>
        ) : null}
      </div>
      <textarea value={rawInput} onChange={e => setRawInput(e.target.value)} />
    </div>
  )
}

export default Input
